import clientConfig from "../../client-config";
import imageUrlBuilder from "@sanity/image-url";

export interface SimpleImageModel {
  asset: {
    _id;
  };
  alt: string;
}

const builder = imageUrlBuilder(clientConfig.sanity);

export function imageUrlFor(source) {
  return builder.image(source);
}
