import { Link } from "gatsby";
import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames/bind";
import Helmet from "react-helmet";

let cx = classNames.bind(styles);

export interface HeaderModel {
  onHideNav?: any;
  onShowNav?: any;
  showNav?: boolean;
  data?: any;
  id?: string;
}

import * as styles from "./header.module.scss";

const Header = ({ onHideNav, onShowNav, showNav, data, id }: HeaderModel) => {
  let navClass = cx({
    showNav,
    nav: true,
    "stop-scrolling": showNav
  });

  return (
    <>
      <header className={styles.root} id={id} aria-label="header">
        <Helmet>
          {showNav && <body className="stop-scrolling" />}
          {!showNav && <body className="" />}
        </Helmet>
        <div className={styles.wrapper}>
          <div className={styles.branding}>
            <div className={styles.logo}>
              <Link to="/" tabIndex={0}>
                {data && data?.logo && data?.logo?.asset && (
                  <img
                    width={180}
                    height={85}
                    src={imageUrlFor(buildImageObj(data.logo))
                      .width(180)
                      .height(85)
                      .fit("crop")
                      .url()}
                    alt={data.logo.alt}
                  />
                )}
              </Link>
            </div>
          </div>

          <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
            {!showNav && <MenuIcon></MenuIcon>}
            {showNav && <CloseIcon></CloseIcon>}
          </button>

          <nav className={navClass}>
            <ul>
              <li>
                <Link to="/" activeClassName={styles.active}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/get-started" activeClassName={styles.active}>
                  Get started
                </Link>
              </li>
              <li>
                <Link to="/solution" activeClassName={styles.active}>
                  Solution
                </Link>
              </li>
              <li>
                <Link to="/customers" activeClassName={styles.active}>
                  Customers
                </Link>
              </li>
              <li>
                <Link to="/archive" activeClassName={styles.active}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/about-us" activeClassName={styles.active}>
                  About us
                </Link>
              </li>
              <li>
                <Link to="/danskebank" activeClassName={styles.active}>
                  Danske Bank
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
