import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import BlockContent from "../block-content";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import * as styles from "./footer.module.scss";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        footerData: sanityFooterSection {
          _rawDescription
          email
          facebookUrl
          linkedinUrl
          twitterUrl
          title
        }
      }
    `}
    render={data => {
      const footerData = data.footerData;
      return (
        <footer className={styles.footer} aria-label="Footer">
          <div className={styles.footerWrapper}>
            <a href={`mailto:${footerData.email}`} style={{ fontSize: "22px" }}>
              {footerData.email}
            </a>
            <div className={styles.som}>
              {footerData.linkedinUrl && (
                <Link to={footerData.linkedinUrl}>
                  <LinkedInIcon />
                </Link>
              )}
              {footerData.facebookUrl && (
                <Link to={footerData.facebookUrl}>
                  <FacebookIcon />
                </Link>
              )}
              {footerData.twitterUrl && (
                <Link to={footerData.twitterUrl}>
                  <TwitterIcon />
                </Link>
              )}
            </div>
            <div className={styles.siteInfo}>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms And Conditions</Link>

              {footerData._rawDescription && (
                <BlockContent blocks={footerData._rawDescription || []} />
              )}
            </div>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
