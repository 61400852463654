import React from "react";
import "../../styles/layout.scss";
import * as styles from "./layout.module.scss";
import loadable from "@loadable/component";
import Header from "../header/header";
import Footer from "../footer/footer";

const ScrollToTop = loadable(() => import("../scroll-to-top/scroll-to-top"));

const Layout = ({ children, onHideNav, onShowNav, showNav, data, textCenter }) => {
  const layoutStyle = textCenter ? styles.content + " " + styles.textCenter : styles.content;
  return (
    <>
      <Header
        data={data}
        onHideNav={onHideNav}
        onShowNav={onShowNav}
        showNav={showNav}
        id="header"
      />
      <div role="contentinfo" className={layoutStyle}>
        {children}
      </div>
      <ScrollToTop showBelow={240}></ScrollToTop>
      <Footer></Footer>
    </>
  );
};

export default Layout;
