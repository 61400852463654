import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout/layout";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";

const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#153e54"
    },
    secondary: {
      main: "#226487"
    }
  },
  shape: {
    borderRadius: 5
  },
  components: {
    // Name of the component
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: "1rem"
        }
      }
    }
  }
});

const query = graphql`
  query SiteLayoutQuery {
    site: sanitySiteSettings {
      title
      subtitle
      seo
      keywords
      logo {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <ThemeProvider theme={theme}>
            <Layout
              {...props}
              showNav={showNav}
              siteTitle={data.site.title}
              data={data.site}
              onHideNav={handleHideNav}
              onShowNav={handleShowNav}
            />
          </ThemeProvider>
        );
      }}
    />
  );
}

export default LayoutContainer;
