import { PortableText } from "@portabletext/react";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

import { Figure } from "./figure";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import { getImageDimensions } from "@sanity/asset-utils";

// Barebones lazy-loaded image component
const SampleImageComponent = ({ value, isInline }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <img
      src={imageUrlFor(buildImageObj(value))
        .width(isInline ? 400 : 800)
        .fit("max")
        .auto("format")
        .url()}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        // Display alongside text if image appears inside a block text span
        display: isInline ? "inline-block" : "block",

        // Avoid jumping around with aspect-ratio CSS property
        aspectRatio: width / height + ""
      }}
    />
  );
};

const components = {
  types: {
    figure: ({ value }) => <Figure node={value}></Figure>,
    code: ({ value = {} }) => {
      const { code, language } = value;

      if (!code) return null;

      if (language === "html") {
        return <div dangerouslySetInnerHTML={{ __html: code }}></div>;
      } else {
        return <SyntaxHighlighter language={language}>{code}</SyntaxHighlighter>;
      }
    }
  }
};

const BlockContent = ({ blocks }) => {
  return <PortableText value={blocks} components={components} />;
};

export default BlockContent;
